import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosInstance } from '../../../axios/requister'
// import { useQuery } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { extractNonEmptyProps } from '../../../helpers/utils'
import { handleRequestBody } from '../utils'
import { formatDate } from '../utils'

const initialValues = {
  delivery_date: null,
  pick_up_date: null,
  pick_up_time: null,
  delivery_time: null,
  name: '',
  length: '',
  width: '',
  height: '',
  quantity: '',
  weight: '',
  customer: '',
  shipper: '',
  consignee: '',
  dispatcher: '',
  pick_up_location: '',
  destination: '',
  load_type: 'FTL',
  goods_info: 'No',
  shipment: '',
  commodity: '',
  equipment: '',
}

const convertTo24HourFormat = (time12h) => {
  const [time, modifier] = time12h.split(' ')

  let [hours, minutes] = time.split(':')

  if (hours === '12') {
    hours = '00'
  }

  if (modifier?.toLowerCase() === 'pm') {
    hours = parseInt(hours, 10) + 12
  }

  // Pad hours and minutes with leading zeros if needed
  hours = hours.toString().padStart(2, '0')
  minutes = minutes.padStart(2, '0')

  return `${hours}:${minutes}`
}
console.log(convertTo24HourFormat('9:11 AM'))

const convertTo12HourFormat = (time24) => {
  const [hours, minutes] = time24.split(':')
  const hoursInt = parseInt(hours, 10)
  const suffix = hoursInt >= 12 ? 'PM' : 'AM'
  const hours12 = ((hoursInt + 11) % 12) + 1 // Convert 0 to 12 for 12AM
  return `${hours12}:${minutes} ${suffix}`
}

const mapLoad = (data = {}) => {
  return {
    ...data,
    ...['customer', 'dispatcher', 'consignee', 'shipper'].reduce(
      (obj, key) => ({
        ...obj,
        [key]: data[key]
          ? {
              id: data[key].id,
              value: data[key].username,
              label: (
                <div className="flex gap-2">
                  {data[key].first_name} {data[key].last_name}
                  <span className="my-auto text-sm text-gray-500">
                    {data[key].username}
                  </span>
                </div>
              ),
            }
          : null,
      }),
      {}
    ),

    shipment: data?.shipment
      ? {
          id: data?.shipment?.id,
          value: data?.shipment?.name,
          label: (
            <div className="flex justify-between">
              <span>{data?.shipment?.name}</span>
              <span className="my-auto text-xs text-gray-500">
                {' '}
                Created at: {formatDate(data?.shipment?.created_at)}
              </span>
            </div>
          ),
        }
      : null,
    pick_up_location: data?.pick_up_location
      ? {
          id: data?.pick_up_location?.id,
          value: data?.pick_up_location?.id,
          label: data?.pick_up_location?.building_name,
        }
      : null,
    destination: data?.destination
      ? {
          id: data?.destination?.id,
          value: data?.destination?.id,
          label: data?.destination?.building_name,
        }
      : null,
    pick_up_time: data.pick_up_time ? convertTo24HourFormat(data.pick_up_time) : null,
    delivery_time: data.delivery_time ? convertTo24HourFormat(data.delivery_time) : null,
  }
}

const mapUnits = (units, data) => {
  return {
    length: units === 'imperial' ? data.length : (data.length / 2.54).toFixed(2),
    width: units === 'imperial' ? data.width : (data.width / 2.54).toFixed(2),
    height: units === 'imperial' ? data.height : (data.height / 2.54).toFixed(2),
    weight: units === 'imperial' ? data.weight : (data.weight * 2.20462).toFixed(2),
  }
}

const getErrorMessage = (errorData) => {
  if (!errorData) return 'Something went wrong'
  return Array.isArray(errorData)
    ? errorData.join(', ')
    : typeof errorData === 'object'
    ? Object.keys(errorData)
        .map((key) => `${key}: ${errorData[key]}`)
        .join(', ')
    : errorData
}

const useLoad = ({ formType }) => {
  const params = useParams()
  const id = formType === 'create' ? undefined : params?.id

  const navigate = useNavigate()

  const [loadInfo, setLoadInfo] = useState({
    units: 'imperial',
    isExistingShipment: true,
    selectorKeyForDrop: 'x',
    selectorKeyForPickup: 'y',
    creatLoadLoading: false,
    editLoadLoading: false,
    draftLoading: false,
    error: '',
  })

  const setUnits = (units) => setLoadInfo((info) => ({ ...info, units }))
  const setIsExistingShipment = (isExistingShipment) =>
    setLoadInfo((info) => ({ ...info, isExistingShipment }))
  const setSelectorKeyForPickup = (selectorKeyForPickup) =>
    setLoadInfo((info) => ({ ...info, selectorKeyForPickup }))
  const setSelectorKeyForDrop = (selectorKeyForDrop) =>
    setLoadInfo((info) => ({ ...info, selectorKeyForDrop }))
  const setCreateLoadLoading = (creatLoadLoading) =>
    setLoadInfo((info) => ({ ...info, creatLoadLoading }))
  const setEditLoadLoading = (editLoadLoading) =>
    setLoadInfo((info) => ({ ...info, editLoadLoading }))
  const setDraftLoading = (draftLoading) =>
    setLoadInfo((info) => ({ ...info, draftLoading }))
  const setError = (error) => setLoadInfo((info) => ({ ...info, error }))

  const onSubmitCreate = (data) => {
    const createLoad = (values) => {
      setCreateLoadLoading(true)
      axiosInstance
        .post(`/shipment/load/`, {
          ...values,
          pick_up_time: values.pick_up_time
            ? convertTo12HourFormat(values.pick_up_time)
            : null,
          delivery_time: values.delivery_time
            ? convertTo12HourFormat(values.delivery_time)
            : null,
        })
        .then(() => {
          toast.success('Load was created successfully')
          navigate(`/load`)
        })
        .catch((error) => {
          toast.error('Error Creating Load')
          const errorData = error?.response?.data.detail

          setError(getErrorMessage(errorData))
        })
        .finally(() => setCreateLoadLoading(false))
    }

    const values = extractNonEmptyProps({
      ...data,
      ...mapUnits(loadInfo.units, data),
      customer: data.customer?.value,
      shipper: data.shipper?.value,
      dispatcher: data.dispatcher?.value,
      consignee: data.consignee?.value,
      pick_up_location: data.pick_up_location?.value,
      destination: data.destination?.value,
    })

    if (!loadInfo.isExistingShipment) {
      axiosInstance
        .post('/shipment/', { name: '' })
        .then((response) => createLoad({ ...values, shipment: response?.data.id }))
        .catch((error) => {
          toast.error('Error retrieving load shipment')
          const errorData = error?.response?.data
          setError(getErrorMessage(errorData))
        })
    } else {
      createLoad({ ...values, shipment: values.shipment.id })
    }
  }

  const onSubmitEdit = (values) => {
    const editLoad = (values) => {
      setEditLoadLoading(true)
      axiosInstance
        .patch(`/shipment/load/${id}/`, {
          ...values,
          pick_up_time: values.pick_up_time
            ? convertTo12HourFormat(values.pick_up_time)
            : null,
          delivery_time: values.delivery_time
            ? convertTo12HourFormat(values.delivery_time)
            : null,
        })
        .then(() => {
          toast.success('Load was updated successfully')
          navigate(`/load-details/${id}/`)
        })
        .catch((error) => {
          toast.error('Error Updating Load')
          const errorData = error?.response?.data.detail
          setError(getErrorMessage(errorData))
        })
        .finally(() => setEditLoadLoading(false))
    }

    if (!loadInfo.isExistingShipment) {
      axiosInstance
        .post('/shipment/', {
          name: values.shipment,
        })
        .then((response) =>
          editLoad({
            ...handleRequestBody(values, loadInfo.units),
            shipment: response.data.id,
          })
        )
        .catch((error) => {
          toast.error('Error retrieving load shipment')
          const errorData = error?.response?.data
          setError(getErrorMessage(errorData))
        })
    } else {
      editLoad({
        ...handleRequestBody(values, loadInfo.units),
        shipment: values.shipment.id,
      })
    }
  }

  let onSubmit
  switch (formType) {
    case 'create':
    case 'copy':
    case 'draft':
      onSubmit = onSubmitCreate
      break
    case 'edit':
      onSubmit = onSubmitEdit
      break
    default:
      throw new Error('Form type is not supported')
  }

  const createDraft = async (values) => {
    const data = extractNonEmptyProps({
      ...values,
      ...mapUnits(loadInfo.units, values),
      customer: values.customer?.id,
      shipper: values.shipper?.id,
      dispatcher: values.dispatcher?.id,
      consignee: values.consignee?.id,
      pick_up_location: values.pick_up_location?.id || null,
      destination: values.destination?.id || null,
      pick_up_time: values.pick_up_time
        ? convertTo12HourFormat(values.pick_up_time)
        : null,
      delivery_time: values.delivery_time
        ? convertTo12HourFormat(values.delivery_time)
        : null,
      shipment: values.shipment?.id || null,
      id: null,
    })

    setDraftLoading(true)

    try {
      await axiosInstance.post(`/shipment/load/draft/`, data)
      toast.success('Load draft was saved successfully')
      navigate('/drafts')
    } catch (error) {
      if (error.response?.status === 500) {
        toast.error('internal server error')
        setError( getErrorMessage('internal server error'))
      }
      else {
        toast.error(error.response?.data?.details || 'Something went wrong.')
        const errorData = error?.response?.data
        setError(getErrorMessage(errorData))
      }
    } finally {
      setDraftLoading(false)
    }
  }

  const updateDraft = async (values) => {
    const data = {
      ...values,
      ...mapUnits(loadInfo.units, values),
      customer: values.customer?.id,
      shipper: values.shipper?.id,
      dispatcher: values.dispatcher?.id,
      consignee: values.consignee?.id,
      pick_up_location: values.pick_up_location?.id || null,
      destination: values.destination?.id || null,
      shipment: values.shipment?.id || null,
      pick_up_time: values.pick_up_time
        ? convertTo12HourFormat(values.pick_up_time)
        : null,
      delivery_time: values.delivery_time
        ? convertTo12HourFormat(values.delivery_time)
        : null,
    }
  
    setDraftLoading(true)
    axiosInstance
      .patch(`/shipment/load/draft/${id}/`, data)
      .then(() => {
        toast.success('Load draft was updated successfully')
        navigate('/drafts')
      })
      .catch((error) => {
        if (error.response?.status === 500) {
          toast.error('internal server error')
          setError( getErrorMessage('internal server error'))
        }
        else {
          toast.error(error.response?.data?.details || 'Something went wrong.')
          const errorData = error?.response?.data
          setError(getErrorMessage(errorData))
        }
        
      })
      .finally(() => setDraftLoading(false))
  }

  return {
    id,
    loadInfo,
    setUnits,
    setIsExistingShipment,
    setSelectorKeyForPickup,
    setSelectorKeyForDrop,
    createDraft,
    updateDraft,
    mapLoad,
    mapUnits,
    initialValues,
    onSubmit,
    convertTo24HourFormat,
  }
}

export default useLoad
