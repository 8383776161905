import usePagination from '../../../Hooks/usePagination'
import { axiosInstance } from '../../../axios/requister'
import { useQuery } from '@tanstack/react-query'
import FIconWrapper from '../../../components/FIconWrapper'
import FButton from '../../../components/FButton'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import InsuranceList from '../../Insurance/InsuranceList'
import PaginationControls from '../../../components/PaginationControls'
import ESpinner from '../../../components/ESpinner'
import { useState } from 'react'
import FModal from '../../../components/FModal'
import FInputField from '../../../components/FInputField'
import { toast } from 'react-toastify'
import MessageCard from '../../../components/MessageCard'

const AddLoadInsuranceModal = ({
  loadId,
  isModelOpen,
  setIsModalOpen,
  availableInsuranceData,
  refetchLoadInsurances,
  previousInsurances = [],
}) => {
  const userInsurances = availableInsuranceData['user_insurances']
  const companyInsurances = availableInsuranceData['company_insurances']

  const [insurances, setInsurances] = useState(previousInsurances)
  const [isLoading, setIsLoading] = useState(false)

  const handleSelectInsurance = (insurance) => {
    const index = insurances.findIndex((ins) => ins.id === insurance.id)

    if (index !== -1) {
      setInsurances(insurances.filter((ins) => ins.id !== insurance.id))
    } else {
      setInsurances([...insurances, insurance])
    }
  }

  const handleSubmitInsurances = async () => {
    try {
      setIsLoading(true)
      await axiosInstance.post('/shipment/load/insurance/', {
        load: loadId,
        insurances: insurances.map((insurance) => insurance.id),
      })
      await refetchLoadInsurances()
      toast.success('Success.')
      setIsModalOpen(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FModal
      isOpen={isModelOpen}
      setIsOpen={setIsModalOpen}
      title="Select insurances you want to assign to the Load"
    >
      <p className="text-lg text-gray-500">Your Company Insurances</p>
      <div className="my-3 ml-3 flex flex-wrap items-center gap-2">
        {companyInsurances.map((insurance) => (
          <div
            key={insurance.id}
            className="mb-2 flex cursor-pointer items-center gap-1"
            onClick={() => handleSelectInsurance(insurance)}
          >
            <FInputField
              className="flex h-4 w-4 cursor-pointer text-orange-500
                                focus:shadow-none focus:ring-0 focus:ring-offset-0"
              type="checkbox"
              name={insurance.name}
              checked={insurances.findIndex((ins) => ins.id === insurance.id) !== -1}
              onChange={() => handleSelectInsurance(insurance)}
            />
            <p className="mx-1 text-gray-500">{insurance.name}</p>
          </div>
        ))}
      </div>
      <p className="text-lg text-gray-500">Your Own Insurances</p>
      <div className="my-3 ml-3 flex flex-wrap items-center gap-2">
        {userInsurances.map((insurance) => (
          <div
            key={insurance.id}
            className="mb-2 flex cursor-pointer items-center gap-1"
            onClick={() => handleSelectInsurance(insurance)}
          >
            <FInputField
              className="flex h-4 w-4 cursor-pointer text-orange-500
                                focus:shadow-none focus:ring-0 focus:ring-offset-0"
              type="checkbox"
              name={insurance.name}
              checked={insurances.findIndex((ins) => ins.id === insurance.id) !== -1}
              onChange={() => handleSelectInsurance(insurance)}
            />
            <p className="mx-1 text-gray-500">{insurance.name}</p>
          </div>
        ))}
      </div>
      <FButton className="ml-auto" onClick={handleSubmitInsurances} disabled={isLoading}>
        <FIconWrapper>
          <AiOutlinePlusCircle size={20} />
          <span className={'text-sm font-medium'}>Assign</span>
        </FIconWrapper>
      </FButton>
    </FModal>
  )
}

const LoadInsuranceTab = ({ id, isUnderInsured }) => {
  const [isModelOpen, setIsModalOpen] = useState(false)

  const { page, getNextPage, getPreviousPage } = usePagination()

  const {
    data: loadInsuranceData,
    status,
    refetch: refecthLoadInsurances,
  } = useQuery(
    ['loadInsuranceData', page],
    async () => {
      const response = await axiosInstance.get(
        `/shipment/load/${id}/insurance/?page=${page}`
      )
      return response.data
    },
    {
      keepPreviousData: false,
      retry: false,
      enabled: true,
    }
  )

  const {
    data: availableInsuranceData,
    status: availableInsuranceStatus,
    // refetch,
  } = useQuery(
    ['availableInsuranceData', page],
    async () => {
      const response = await axiosInstance.get(`/insurance/available/?page=${page}`)
      return response.data
    },
    {
      keepPreviousData: true,
      retry: false,
      enabled: true,
    }
  )

  const totalNumberOfPages = Math.ceil(loadInsuranceData?.count / 5) || 0

  if (status === 'loading' || availableInsuranceStatus === 'loading') {
    return (
      <div>
        <div className={'mt-60 flex w-full items-center justify-center '}>
          <div className={'f-col-center-center'}>
            <ESpinner isVisible={true} size={30} />
            <span className="mt-5">Loading...</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <AddLoadInsuranceModal
        loadId={id}
        isModelOpen={isModelOpen}
        setIsModalOpen={setIsModalOpen}
        availableInsuranceData={availableInsuranceData}
        previousInsurances={loadInsuranceData?.results}
        refetchLoadInsurances={refecthLoadInsurances}
      />
      <div
        className={
          'sticky top-0 z-10  flex w-full  justify-between border-b bg-white p-3 ' +
          'text-left text-xl font-semibold text-gray-700 border-t'
        }
      >
        <FIconWrapper>
          <span className={''}>Insurances</span>
        </FIconWrapper>
        <FButton onClick={() => setIsModalOpen(true)}>
          <FIconWrapper>
            <AiOutlinePlusCircle size={20} />
            <span className={'text-sm font-medium'}>Assign Insurances</span>
          </FIconWrapper>
        </FButton>
      </div>
      {isUnderInsured && (
        <div className="p-3">
          <MessageCard
            message="Warning: Insurance value is below declared value."
            type="pending"
          />
        </div>
      )}

      {!loadInsuranceData.results.length ? (
        <div className={'my-16 flex flex-col items-center justify-center'}>
          <img
            src="https://storage.googleapis.com/freight_static_files/empty-state.svg"
            alt={'empty_state'}
            width={200}
            className={''}
          />
          <span className={'mt-5 font-semibold text-gray-700'}>No Insurances found</span>
        </div>
      ) : (
        <InsuranceList insuranceData={loadInsuranceData} />
      )}

      {loadInsuranceData?.results?.length > 0 && (
        <PaginationControls
          page={page}
          totalNumberOfPages={totalNumberOfPages}
          getNextPage={getNextPage}
          getPreviousPage={getPreviousPage}
        />
      )}
    </>
  )
}

export default LoadInsuranceTab
