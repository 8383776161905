import FModal from '../../../components/FModal'
import FFormWrapper from '../../../components/FFormWrapper'
import FLabel from '../../../components/FLabel'
import { useParams } from 'react-router-dom'
import FButton from '../../../components/FButton'
import FIconWrapper from '../../../components/FIconWrapper'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { useFormik } from 'formik'
import { axiosInstance } from '../../../axios/requister'
import React, { useRef, useState, useContext } from 'react'
import ESpinner from '../../../components/ESpinner'
import { toast } from 'react-toastify'
import AsyncSelect from 'react-select/async'
import { NoOptionsMessage } from '../utils'
import { UserContext } from './../../../components/UserContext'

const AddCarrierModal = ({ isCarrierModalOpen, setIsCarrierModalOpen, callback }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [serverError, setServerError] = useState('')
  const { id } = useParams()
  const { user } = useContext(UserContext)

  const carrierRef = useRef(null)

  const loadCarrier = (inputValue, callback) => {
    axiosInstance
      .post(`/shipment/filter-contact/`, {
        type: 'carrier',
        keyword: inputValue,
      })

      .then((res) => {
        const options = res.data.results.map((carrier) => {
          return {
            value: carrier.contact.username,
            label: carrier.contact.username,
          }
        })

        if (user.user_type.includes('carrier')) {
          callback([
            {
              value: user.username,
              label: user.username + ' (me)',
            },
            ...options,
          ])
        } else {
          callback(options)
        }
      })
      .catch(() => {})
  }

  const Formik = useFormik({
    initialValues: {
      carrier: '',
    },

    onSubmit: () => {
      setServerError('')
      setIsLoaded(true)
      axiosInstance
        .patch(`/shipment/load/${id}/`, {
          carrier: Formik.values.carrier.value,
          action: 'assign carrier',
        })
        .then(() => {
          toast.success('Carrier added Successfully')
          setIsLoaded(false)
          setIsCarrierModalOpen(false)
          callback()
          Formik.resetForm(true)
        })
        .catch(() => {
          setServerError('Error adding carrier')
          toast.error('Error adding carrier')
          setIsLoaded(false)
        })
    },

    validate: (values) => {
      const errors = {}
      if (values.carrier === '') {
        errors.carrier = 'Carrier name is required'
      }
      return errors
    },
  })

  return (
    <>
      <FModal
        callbackFunction={() => {
          Formik.resetForm()
          setServerError('')
        }}
        title={'Add Carrier to the load'}
        isOpen={isCarrierModalOpen}
        setIsOpen={() => {
          setIsCarrierModalOpen(false)
        }}
      >
        <form onSubmit={Formik.handleSubmit}>
          <FFormWrapper className={'w-12/12'}>
            <FLabel onClick={() => carrierRef.current.focus()}>Carrier name</FLabel>

            <AsyncSelect
              ref={carrierRef}
              openMenuOnFocus={true}
              defaultOptions
              value={Formik.values.carrier}
              cacheOptions
              loadOptions={loadCarrier}
              onChange={(e) => {
                Formik.setFieldValue('carrier', e)
              }}
              onBlur={() => {
                Formik.setFieldTouched('carrier', true)
              }}
              placeholder={'Select your carrier'}
              noOptionsMessage={NoOptionsMessage}
            />
            <small className={'text-left font-medium text-red-700'}>
              {Formik?.errors?.carrier}
            </small>
            {serverError !== '' && (
              <small className={'text-left font-medium text-red-700'}>
                {serverError}
              </small>
            )}
          </FFormWrapper>

          <div className={'flex w-full flex-row-reverse gap-1'}>
            <FButton type={'submit'} onClick={() => setIsCarrierModalOpen(true)}>
              <FIconWrapper>
                <AiOutlinePlusCircle size={20} />
                Add Carrier
                <ESpinner isVisible={isLoaded} />
              </FIconWrapper>
            </FButton>
            <FButton
              btnType={'secondary'}
              onClick={() => {
                Formik.resetForm()
                setIsCarrierModalOpen(false)
                setServerError('')
              }}
            >
              <FIconWrapper>Cancel</FIconWrapper>
            </FButton>
          </div>
        </form>
      </FModal>
    </>
  )
}
export default AddCarrierModal
