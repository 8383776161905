import FCard from '../../../components/FCard'
import FButton from '../../../components/FButton'
import CreateBidModal from './CreateBidModal'
import { React, useState, useContext } from 'react'
import { BsArrowRight } from 'react-icons/bs'
import Status from '../../../components/Status'
import { axiosInstance } from '../../../axios/requister'
import { toast } from 'react-toastify'
import ESpinner from '../../../components/ESpinner'
import { UserContext } from '../../../components/UserContext'

const StartBidCard = ({ response, userInfo, startBidInfo, Refetch }) => {
  const [isBidModalOpen, setisBidModalOpen] = useState(false)
  const [isLoadedRejected, setIsLoadedRejected] = useState(false)
  const { user } = useContext(UserContext)
  const username = userInfo.response?.username
  const isDispatcher =
    response?.dispatcher?.username === username && user.selected_role === 'dispatcher'
  const isCustomer =
    response?.customer === username && user.selected_role === 'shipment party'
  const isCarrier = response?.carrier?.username === username && user.selected_role === 'carrier'
  const isManager = user.selected_role === 'manager'

  const handleReject = () => {
    setIsLoadedRejected(true)
    axiosInstance
      .post('/shipment/reject-load/', {
        load: response?.id,
      })
      .then(() => {
        setIsLoadedRejected(false)
        toast.success('Offer rejected ')
        Refetch()
        startBidInfo.Refetch()
      })
      .catch(() => {
        setIsLoadedRejected(false)
        toast.error('Offer rejected field')
      })
  }

  return (
    <>
      <CreateBidModal
        startBidInfo={startBidInfo}
        Refetch={Refetch}
        isBidModalOpen={isBidModalOpen}
        setisBidModalOpen={setisBidModalOpen}
        response={response}
      />
      {isManager && startBidInfo?.response?.length >= 2 && (
        <>
          <div className="p-2">
            <FCard className="my-2">
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  First Offer with Customer
                </span>
              </FCard.Header>
              <FCard.Body className="justify-between lg:flex">
                <div className="my-auto lg:flex">
                  <div className="flex gap-1 text-lg font-semibold text-slate-700">
                    <span className="text-lg font-semibold text-gray-400 ">From:</span>
                    {startBidInfo.response[0]?.party_1}
                  </div>
                  <BsArrowRight
                    size={25}
                    className="my-auto ml-2 mr-2 text-slate-600 lg:block"
                  />
                  <div className="flex gap-1 text-lg font-semibold text-slate-700">
                    <span className="text-lg font-semibold text-gray-400 ">To:</span>
                    {startBidInfo.response[0]?.party_2}
                  </div>
                </div>
                <div className="flex gap-5 lg:flex-col lg:gap-0">
                  <span className="text-base font-normal text-slate-600">BID PRICE</span>
                  <span className="text-xl font-semibold text-green-500">
                    $ {startBidInfo.response[0]?.current}
                  </span>
                </div>
                <div>
                  <Status statusName={startBidInfo.response[0]?.status} />
                </div>
              </FCard.Body>
            </FCard>
          </div>
          <div className="p-2">
            <FCard className="my-2">
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Second Offer with Carrier
                </span>
              </FCard.Header>
              <FCard.Body className="justify-between lg:flex">
                <div className="my-auto lg:flex ">
                  <div className="flex gap-1 text-lg font-semibold text-slate-700">
                    <span className="text-lg font-semibold text-gray-400 ">From:</span>
                    {startBidInfo.response[1]?.party_1}
                  </div>
                  <BsArrowRight
                    size={25}
                    className="my-auto ml-2 mr-2 text-slate-600 lg:block"
                  />
                  <div className="flex gap-1 text-lg font-semibold text-slate-700">
                    <span className="text-lg font-semibold text-gray-400 ">To:</span>
                    {startBidInfo.response[1]?.party_2}
                  </div>
                </div>
                <div className="flex gap-5 lg:flex-col lg:gap-0">
                  <span className="text-base font-normal text-slate-600">BID PRICE</span>
                  <span className="text-xl font-semibold text-red-500">
                    - $ {startBidInfo.response[1]?.current}
                  </span>
                </div>
                <div>
                  <Status statusName={startBidInfo.response[1]?.status} />
                </div>
              </FCard.Body>
            </FCard>
          </div>
        </>
      )}

      {isManager && startBidInfo?.response?.length === 1 && (
        <div className="p-2">
          <FCard className="my-2">
            <FCard.Header>
              <span className="text-lg font-semibold text-gray-800">
                Offer with Customer
              </span>
            </FCard.Header>
            <FCard.Body className="justify-between lg:flex">
              <div className="my-auto lg:flex">
                <div className="flex gap-1 text-lg font-semibold text-slate-700">
                  <span className="text-lg font-semibold text-gray-400 ">From:</span>
                  {startBidInfo.response[0]?.party_1}
                </div>
                <BsArrowRight
                  size={25}
                  className="my-auto ml-2 mr-2 text-slate-600 lg:block"
                />
                <div className="flex gap-1 text-lg font-semibold text-slate-700">
                  <span className="text-lg font-semibold text-gray-400 ">To:</span>
                  {startBidInfo.response[0]?.party_2}
                </div>
              </div>
              <div className="flex gap-5 lg:flex-col lg:gap-0">
                <span className="text-base font-normal text-slate-600">BID PRICE</span>
                <span className="text-xl font-semibold text-green-500">
                  $ {startBidInfo.response[0]?.current}
                </span>
              </div>
              <div>
                <Status statusName={startBidInfo.response[0]?.status} />
              </div>
            </FCard.Body>
          </FCard>
        </div>
      )}

      {isManager && startBidInfo?.response?.length === 0 && (
        <div className="p-2 text-center text-2xl text-gray-400">
          No offers available for now.
        </div>
      )}
      {response?.status.toLowerCase() === 'Created'.toLowerCase() && isDispatcher && (
        <div className={'m-2 mt-6'}>
          <FCard className={' my-2'}>
            <FCard.Header>
              <span className="text-lg font-semibold text-gray-800">
                Bid Customer awaiting your action
              </span>
            </FCard.Header>
            <div>
              <FCard.Body className=" justify-between  lg:flex">
                <div className="flex">
                  <div className="mb-3 flex flex-col gap-2 lg:mb-0 lg:gap-0">
                    <div className="gap-2 lg:flex">
                      <div className="text-lg font-semibold text-slate-700 ">
                        {'You have been assigned to this load as a dispatcher.'}
                      </div>
                    </div>
                    <span className="text-sm text-slate-500 ">
                      {response.name + ' ' + 'Load'}
                    </span>
                  </div>
                </div>
                <div
                  className={`${userInfo.response?.username === response?.dispatcher?.username
                    ? '   flex  '
                    : 'hidden'
                    }`}
                >
                  <div className=" flex h-fit  gap-5">
                    <FButton
                      btnType={'success'}
                      className=" px-10  hover:bg-green-600"
                      onClick={() => setisBidModalOpen(true)}
                    >
                      Start bid
                    </FButton>
                    <FButton
                      onClick={() => handleReject()}
                      btnType={'danger'}
                      className={`${response?.created_by !== response?.dispatcher?.username
                        ? '  gap-2 hover:bg-red-600 '
                        : 'hidden'
                        }`}
                    >
                      Reject
                      <ESpinner isVisible={isLoadedRejected} />
                    </FButton>
                  </div>
                </div>
              </FCard.Body>
            </div>
          </FCard>
        </div>
      )}
      {response?.status.toLowerCase() === 'Assigning Carrier'.toLowerCase() &&
        isDispatcher &&
        response?.carrier?.username !== null && (
          <div className={'m-2 mt-6'}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Start your bid with Carrier
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="flex">
                    <div className="mb-3 flex flex-col gap-2 lg:mb-0 lg:gap-0">
                      <div className="gap-2 lg:flex">
                        <div className="text-lg font-semibold text-slate-700 ">
                          You must start a bid with the carrier in order to finish the
                          process.
                        </div>
                      </div>
                      <span className="text-sm text-slate-500 ">
                        {response.name + ' ' + 'Load'}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`${userInfo.response?.username == response?.dispatcher?.username
                      ? '   flex  '
                      : 'hidden'
                      }`}
                  >
                    <div className=" flex h-fit  gap-5">
                      <FButton
                        btnType={'success'}
                        className=" px-10  hover:bg-green-600"
                        onClick={() => setisBidModalOpen(true)}
                      >
                        Start a bid with carrier
                      </FButton>
                    </div>
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {(response?.status.toLowerCase() === 'ready for pickup' ||
        response?.status.toLowerCase() === 'in transit' ||
        response?.status.toLowerCase() === 'delivered') &&
        isDispatcher && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
                <span className=" text-base font-normal text-gray-400">
                  (With carrier)
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.dispatcher?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : response?.dispatcher?.username}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block"></div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[1]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-red-500">
                      $ {'-' + startBidInfo.response[1]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[1]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
                <span className=" text-base font-normal text-gray-400">
                  (With customer)
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.dispatcher?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : response?.dispatcher?.username}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block"></div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-green-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {response?.status.toLowerCase() === 'Awaiting carrier'.toLowerCase() &&
        isDispatcher && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
                <span className=" text-base font-normal text-gray-400">
                  (With carrier)
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.dispatcher?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : response?.dispatcher?.username}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block"></div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[1]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[1]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[1]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {response?.status.toLowerCase() === 'Awaiting Customer'.toLowerCase() &&
        isDispatcher && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.dispatcher?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : response?.dispatcher?.username}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />

                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.customer?.username}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {response?.status.toLowerCase() === 'Awaiting carrier'.toLowerCase() &&
        isDispatcher && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
                <span className="text-base font-normal text-gray-400">
                  (With customer)
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.dispatcher?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : startBidInfo.response[0]?.party_1}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block">
                      {/* <FAvatar
                        width={35}
                        name={response?.customer?.username}
                        className="mr-3 rounded-lg"
                      /> */}
                    </div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {response?.status.toLowerCase() === 'Assigning Carrier'.toLowerCase() &&
        isDispatcher && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
                <span className="text-base font-normal text-gray-400">
                  (With customer)
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_1 === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : startBidInfo.response[0]?.party_1}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block">
                      {/* <FAvatar
                        width={35}
                        name={response?.customer?.username}
                        className="mr-3 rounded-lg"
                      /> */}
                    </div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {response?.status.toLowerCase() === 'Awaiting Dispatcher'.toLowerCase() &&
        isDispatcher &&
        response?.carrier?.username !== null && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
                <span className="text-base font-normal text-gray-400">
                  (With customer)
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_1 === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : startBidInfo.response[0]?.party_1}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block">
                      {/* <FAvatar
                        width={35}
                        name={response?.customer?.username}
                        className="mr-3 rounded-lg"
                      /> */}
                    </div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {response?.status.toLowerCase() === 'Awaiting Dispatcher'.toLowerCase() &&
        isCustomer && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.customer?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : response?.customer?.username}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block">
                      {/* <FAvatar
                        width={35}
                        name={response?.dispatcher?.username}
                        className="mr-3 rounded-lg"
                      /> */}
                    </div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.dispatcher?.username}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {response?.status.toLowerCase() === 'Awaiting Dispatcher'.toLowerCase() &&
        isCarrier && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.customer?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : startBidInfo.response[0]?.party_1}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block"></div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
      {(response?.status.toLowerCase() === 'ready for pickup' ||
        response?.status.toLowerCase() === 'in transit' ||
        response?.status.toLowerCase() === 'delivered') &&
        isCarrier && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.customer?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : startBidInfo.response[0]?.party_1}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block"></div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_2}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}

      {response?.status.toLowerCase() === 'Assigning Carrier'.toLowerCase() &&
        isCustomer && (
          <div className={'m-2 mt-6 '}>
            <FCard className={' my-2'}>
              <FCard.Header>
                <span className="text-lg font-semibold text-gray-800">
                  Your bid information{' '}
                </span>
              </FCard.Header>
              <div>
                <FCard.Body className=" justify-between  lg:flex">
                  <div className="my-auto flex ">
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {response?.customer?.username === userInfo.response?.username
                        ? 'You' + ' ' + 'place a bid  with' + '   '
                        : startBidInfo.response[0]?.party_2}
                    </div>

                    <BsArrowRight
                      size={25}
                      className="my-auto ml-2 mr-2  text-slate-600 lg:block "
                    />
                    <div className="hidden lg:block">
                      {/* <FAvatar
                        width={35}
                        name={response?.dispatcher?.username}
                        className="mr-3 rounded-lg"
                      /> */}
                    </div>
                    <div className=" text-lg font-semibold text-slate-700 ">
                      {startBidInfo.response[0]?.party_1}
                    </div>
                  </div>
                  <div className="flex gap-5 lg:flex-col lg:gap-0 ">
                    <span className="text-base font-normal text-slate-600">
                      YOUR BID PRICE
                    </span>
                    <span className="text-xl font-semibold text-primary-500">
                      $ {startBidInfo.response[0]?.current}
                    </span>
                  </div>
                  <div>
                    <Status statusName={startBidInfo.response[0]?.status} />
                  </div>
                </FCard.Body>
              </div>
            </FCard>
          </div>
        )}
    </>
  )
}
export default StartBidCard
