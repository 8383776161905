/* eslint-disable object-curly-spacing */
import React, { useState } from 'react'
import NotificationTab from './NotificationTab'
import DetailssTab from './DitailsTab'
import PasswordTab from './PasswordTab'

const Settings = () => {
  const [tab, setTab] = useState('My details')

  return (
    <div>
      <div
        className={
          'sticky top-0 z-10  flex w-full   border-b bg-white p-3 ' +
          'text-left text-xl font-semibold text-gray-700'
        }
      >
        <span className={''}>Settings</span>
      </div>
      {/* <div
        className={
          'flex w-full flex-col p-3  ' + 'text-left  font-semibold text-gray-700'
        }
      >
        <span className={' mb-5 text-2xl'}>Profile Settings</span>
      </div> */}
      <div
        style={{ top: 'top: 53px' }}
        className={' mb-3 flex gap-5 border-b-2 bg-white  px-2 '}
      >
        <div
          onClick={() => setTab('My details')}
          className={` ${tab === 'My details' && '  border-b-4'
            } cursor-pointer border-primary-500  p-2 `}
        >
          <span className={'text-sm font-semibold  text-gray-700'}>My Details</span>
        </div>
        {/* ------------------------------------------------ */}
        <div
          onClick={() => setTab('Notification')}
          className={` ${tab === 'Notification' && '  border-b-4'
            } cursor-pointer border-primary-500  p-2 `}
        >
          <span className={'text-sm font-semibold  text-gray-700'}>Notification</span>
        </div>
        {/* ------------------------------------------------ */}
        <div
          onClick={() => setTab('Password')}
          className={` ${tab === 'Password' && '  border-b-4'
            } cursor-pointer border-primary-500  p-2 `}
        >
          <span className={'text-sm font-semibold  text-gray-700'}>Password</span>
        </div>
        {/* ------------------------------------------------ */}
      </div>
      {tab === 'My details' && <DetailssTab />}
      {tab === 'Notification' && <NotificationTab />}
      {tab === 'Password' && <PasswordTab />}
    </div>
  )
}
export default Settings
