import React, { useContext, useEffect, useState } from 'react'
import { RiEdit2Line, RiAddFill } from 'react-icons/ri'
import { UserContext } from '../../components/UserContext'
// import FAvatar from '../../components/FAvatar'
import FInputField from '../../components/FInputField'
import { Link } from 'react-router-dom'
import FButton from '../../components/FButton'
import { MdDeleteOutline } from 'react-icons/md'
import DeleteUserModal from './DeleteUserModal'
import { Tooltip } from '@mui/material'
import AddTaxInfoModal from './AddTaxInfoModal'
import { axiosInstance } from '../../axios/requister'
import { BASE_URL } from '../../env'

export default function DitailsTab() {
  const { user } = useContext(UserContext)
  const [isUsernameEditable, setIsUsernameEditable] = useState(false)
  const [isNameEditable, setIsNameEditable] = useState(false)
  const [isEmailEditable, setIsEmailEditable] = useState(false)
  const [isPNumEditable, setIsPNumEditable] = useState(false)
  const roles = user.user_type.split('-')
  const labelFont = 'text-gray-900 font-medium'
  const [isSaveChanges, setIsSaveChanges] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openTaxInfoModal, setOpenTaxInfoModal] = useState(false)
  const [taxInfo, setTaxInfo] = useState({})

  useEffect(() => {
    if (isUsernameEditable || isNameEditable || isEmailEditable || isPNumEditable) {
      setIsSaveChanges(true)
    }
  }, [isUsernameEditable, isNameEditable, isEmailEditable, isPNumEditable])

  useEffect(() => {
    axiosInstance
      .get(`${BASE_URL}authentication/tax-info/ `)
      .then((res) => {
        setTaxInfo(res)
      })
      .catch(() => {})
  }, [])
  const colorMap = {
    'shipment party': 'bg-blue-100 text-blue-600 border-blue-300 border',
    dispatcher: 'bg-green-100 text-green-600 border-green-300 border',
    carrier: 'bg-yellow-100 text-yellow-600 border-yellow-300 border',
  }
  return (
    <>
      <AddTaxInfoModal
        openTaxInfoModal={openTaxInfoModal}
        setOpenTaxInfoModal={setOpenTaxInfoModal}
      />
      <DeleteUserModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
      />

      <div className="mx-2 my-5 flex flex-col items-center p-4 sm:mx-8 sm:my-8 md:mx-20 md:my-5 ">
        <div className="flex flex-col items-center gap-4 sm:w-full sm:flex-row sm:justify-between">
          <span className=" text-xl font-bold text-gray-700">
            Manage your profile settings 
            <br />{' '}
            <span className="my-3 flex text-base font-normal text-gray-500">
            Manage your profile and account settings on this page.
            <br/> Update your personal information, and adjust your privacy preferences to enhance your user experience.
            </span>
          </span>
        </div>

        <hr className="my-5 w-full border-gray-300" />

        <div className="my-3 flex h-auto w-full flex-col gap-5 bg-white p-5">
          <div className="flex flex-col gap-5 sm:flex-row">
            <span className="w-1/3">
              <h3 className={labelFont}>Username</h3>
            </span>

            <span className="flex w-auto sm:w-1/3">
              {isUsernameEditable ? (
                <FInputField
                  type="text"
                  placeholder="Username"
                  value={user.username}
                  onChange={() => {}}
                  autoFocus
                />
              ) : (
                <h3 className="text-gray-700">{user.username}</h3>
              )}
              <span className="flex  w-1/3 cursor-pointer items-center justify-center sm:hidden">
                <span className="px-0.5"></span>
                <RiEdit2Line
                  size={20}
                  onClick={() => {
                    setIsUsernameEditable(true)
                  }}
                  className="hidden"
                />
              </span>
            </span>
            <span className="hidden  w-1/3 cursor-pointer justify-center sm:visible sm:flex">
              <RiEdit2Line
                size={20}
                onClick={() => {
                  setIsUsernameEditable(true)
                }}
                className="hidden"
              />
            </span>
          </div>
          <hr />
          <div className="flex flex-col gap-5 sm:flex-row">
            <span className="w-1/3">
              <h3 className={labelFont}>Name</h3>
            </span>
            <span className="flex w-auto sm:w-1/3">
              {isNameEditable ? (
                <>
                  <FInputField
                    type="text"
                    placeholder="First Name"
                    value={user.first_name}
                    onChange={() => {}}
                    autoFocus
                  />
                  <FInputField
                    type="text"
                    placeholder="Last Name"
                    value={user.last_name}
                    onChange={() => {}}
                  />
                </>
              ) : (
                <>
                  <h3 className="text-gray-700">{user.first_name}</h3>
                  <span className="px-0.5"></span>
                  <h3 className="text-gray-700">{user.last_name}</h3>
                </>
              )}
              <span className="flex  w-1/3 cursor-pointer items-center justify-center sm:hidden">
                <span className="px-0.5"></span>
                <RiEdit2Line
                  size={20}
                  onClick={() => {
                    setIsNameEditable(true)
                  }}
                  className="hidden"
                />
              </span>
            </span>
            <span className="hidden  w-1/3 cursor-pointer justify-center sm:visible sm:flex">
              <RiEdit2Line
                size={20}
                className="hidden"
                onClick={() => {
                  setIsNameEditable(true)
                }}
              />
            </span>
          </div>
          <hr />
          <div className="flex flex-col gap-5 sm:flex-row">
            <span className="w-1/3">
              <h3 className={labelFont}>Email</h3>
            </span>
            <span className="flex w-auto sm:w-1/3">
              {isEmailEditable ? (
                <FInputField
                  type="email"
                  placeholder="Email"
                  value={user.email}
                  onChange={() => {}}
                  autoFocus
                />
              ) : (
                <h3 className="text-gray-700">{user.email}</h3>
              )}
              <span className="flex  w-1/3 cursor-pointer items-center justify-center sm:hidden">
                <span className="px-0.5"></span>
                <RiEdit2Line
                  className="hidden"
                  size={20}
                  onClick={() => {
                    setIsEmailEditable(true)
                  }}
                />
              </span>
            </span>
            <span className="hidden  w-1/3 cursor-pointer justify-center sm:visible sm:flex">
              <RiEdit2Line
                className="hidden"
                size={20}
                onClick={() => {
                  setIsEmailEditable(true)
                }}
              />
            </span>
          </div>
          <hr />
          <div className="flex flex-col gap-5 sm:flex-row">
            <span className="w-1/3">
              <h3 className={`whitespace-nowrap ${labelFont}`}>Phone Number</h3>
            </span>
            <span className="flex w-auto sm:w-1/3">
              {isPNumEditable ? (
                <FInputField
                  type="text"
                  placeholder="Phone Number"
                  value={user.phone_number}
                  onChange={() => {}}
                  autoFocus
                />
              ) : (
                <h3 className="text-gray-700">{user.phone_number}</h3>
              )}
              <span className="flex  w-1/3 cursor-pointer items-center justify-center sm:hidden">
                <span className="px-0.5"></span>
                <RiEdit2Line
                  className="hidden"
                  size={20}
                  onClick={() => {
                    setIsPNumEditable(true)
                  }}
                />
              </span>
            </span>
            <span className="hidden  w-1/3 cursor-pointer justify-center sm:visible sm:flex">
              <RiEdit2Line
                className="hidden"
                size={20}
                onClick={() => {
                  setIsPNumEditable(true)
                }}
              />
            </span>
          </div>
          <hr />
          <div className="flex flex-col">
            <span className="flex justify-between">
              <h3 className={`whitespace-nowrap ${labelFont} `}>My Roles</h3>
              <div className="flex w-auto flex-col gap-3 whitespace-nowrap sm:w-1/3  lg:flex-row">
                {roles.map((role, index) => (
                  <div
                    key={index}
                    className={`mr-2  px-2 py-1 font-semibold  ${colorMap[role]}`}
                  >
                    {role}
                  </div>
                ))}
              </div>
              {roles.length < 3 ? (
                <Link to={'/roles'}>
                  <Tooltip title="Add New Role" arrow placement="top">
                    <div className="my-auto mr-10 ">
                      <RiAddFill
                        size={25}
                        className="cursor-pointer text-primary-500 hover:text-primary-600"
                      />
                    </div>
                  </Tooltip>
                </Link>
              ) : (
                <span className="mr-10"></span>
              )}
            </span>
          </div>
          <hr />
          <div className="flex flex-col">
            <span className="flex justify-between">
              <h3 className={`whitespace-nowrap ${labelFont} `}>Tax Info</h3>
              <div className="flex w-auto flex-col gap-3 whitespace-nowrap sm:w-1/3  lg:flex-row">
                {taxInfo?.data ? (
                  <div className="font-semibold ">
                    {`This User Has ${taxInfo?.data?.type} Tax Info`}
                  </div>
                ) : (
                  <div className=" font-semibold ">
                    {`This User Has No Tax Info , Please Add Tax Info`}
                  </div>
                )}
              </div>
              {!taxInfo?.data ? (
                <Tooltip
                  title="
                  Add Tax Info
                  "
                  arrow
                  placement="top"
                >
                  <div className="my-auto mr-10 ">
                    <RiAddFill
                      size={25}
                      className="cursor-pointer text-primary-500 hover:text-primary-600"
                      onClick={() => {
                        setOpenTaxInfoModal(true)
                      }}
                    />
                  </div>
                </Tooltip>
              ) : (
                <span className="mr-10"></span>
              )}
            </span>
          </div>
          {/* <TaxInfoForm /> */}
        </div>
        <div className="flex w-full ">
          <div className="flex w-full ">
            <FButton
              onClick={() => {
                setOpenDeleteModal(true)
              }}
              className="w-fit whitespace-nowrap"
              btnType={'danger'}
            >
              <MdDeleteOutline className="mr-2" size={20} /> Delete Account
            </FButton>
          </div>
          <div className="flex w-full justify-end">
            <FButton
              disabled={!isSaveChanges}
              onClick={() => {}}
              className="w-fit"
              btnType={'primary'}
            >
              Save Changes
            </FButton>
          </div>
        </div>
      </div>
    </>
  )
}
