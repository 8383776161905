import StartBidCard from '../StartBidCard'
import OfferCard from '../OfferCard'
import AddCarrierModal from '../AddCarrierModal'
import AddDeclaredValueModal from '../AddDeclaredValueModal'
import { React, useState } from 'react'
import LoadNotesCard from './LoadNotesCard'
import Message from './Message'
import ConfirmStatus from './ConfirmStatus'
import ShipmentContributersCard from './ShipmentContributersCard'
import PickDropCard from './PickDropCard'
import BasicInfoCard from './BasicInfoCard'
import { extractNonEmptyProps } from '../../../../helpers/utils'
import { toast } from 'react-toastify'
import { axiosInstance } from '../../../../axios/requister'
import FButton from '../../../../components/FButton'
import { IoClose } from 'react-icons/io5'
import { Tooltip } from '@mui/material'

const LoadTab = ({ response, userInfo, setisBidModalOpen, startBidInfo, Refetch }) => {
  const [isCarrierModalOpen, setIsCarrierModalOpen] = useState(false)
  const [isDeclaredValueModalOpen, setIsDeclaredValueModalOpen] = useState(false)

  const loadStatusMapper = {
    'Awaiting Customer': 'shipment party',
    'Awaiting Dispatcher': 'Dispatcher',
    'Awaiting Carrier': 'Carrier',
  }

  const handleCloseLoad = () => {
    axiosInstance
      .post(`/shipment/load/close/${response?.id}/`, {
        is_closed: true,
      })
      .then(() => {
        toast.success('Load Closed successfully')
        Refetch()
      })
      .catch((error) => {
        toast.error(error.response?.data?.details || 'Error closing load')
      })
  }

  return (
    <div>
      
      {/* ----------------------------------------------Message Cards Component----------------------------------------------------------- */}
      <Message response={response} userInfo={userInfo} />
      {/* ----------------------------------------------Confirm Status Component----------------------------------------------------------- */}
      <ConfirmStatus response={response} userInfo={userInfo} Refetch={Refetch} />
      <AddCarrierModal
        isCarrierModalOpen={isCarrierModalOpen}
        setIsCarrierModalOpen={setIsCarrierModalOpen}
        callback={() => {
          Refetch()
        }}
      />
      <AddDeclaredValueModal
        isDeclaredValueModalOpen={isDeclaredValueModalOpen}
        setIsDeclaredValueModalOpen={setIsDeclaredValueModalOpen}
        callback={() => {
          Refetch()
        }}
      />
      {startBidInfo.response
        .filter(
          (item) =>
            item.status.toLowerCase() === 'Pending'.toLowerCase() &&
            loadStatusMapper[response?.status]?.toLowerCase() ===
              userInfo.response?.selected_role?.toLowerCase()
        )
        .map(({ id, current, party_1, party_2 }) => (
          <OfferCard
            key={id}
            BidId={id}
            price={current}
            name1={party_1}
            name2={party_2}
            loadname={response?.name + ' ' + 'load'}
            title={'Bid received; action required'}
            Refetch={Refetch}
            startBidInfo={startBidInfo}
          />
        ))}
      <div>
        <StartBidCard
          startBidInfo={startBidInfo}
          Refetch={Refetch}
          response={response}
          setisBidModalOpen={setisBidModalOpen}
          userInfo={userInfo}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className={'px-2 py-2'}>
          <ShipmentContributersCard
            response={response}
            userInfo={userInfo}
            setIsCarrierModalOpen={setIsCarrierModalOpen}
          />
          <PickDropCard response={response} />
          
        </div>
        <div className={'px-2 py-2'}>
          <BasicInfoCard
            response={response}
            setIsDeclaredValueModalOpen={setIsDeclaredValueModalOpen}
          />
          <LoadNotesCard
            LoadId={response?.id}
            LoadDispatcher={response?.dispatcher}
            allLoadParties={Object.values(
              extractNonEmptyProps({
                consignee: response?.consignee?.username,
                dispatcher: response?.dispatcher?.username,
                shipper: response?.shipper?.username,
                customer: response?.customer?.username,
                carrier: response?.carrier?.username,
              })
            )}
          />
          {response?.status === 'Delivered' && response?.is_closed === false && (
            <Tooltip
              title="
          If you close the load, it will be marked as closed for you and no further actions can be taken on it. except for add claim notes.
          "
              arrow
              placement="top"
            >
              <div className="mt-2 flex justify-center p-3">
                <FButton onClick={handleCloseLoad} btnType={'danger'}>
                  <IoClose size={20} /> Close Load
                </FButton>
              </div>
            </Tooltip>
          )}
          {response?.status === 'Delivered' && response?.is_closed === true && (
            <div className="mt-2 flex justify-center text-center font-medium text-red-500 py-3">
              <span>
                This Load is closed for you, no further actions can be taken on it, except
                for add claim notes.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default LoadTab

